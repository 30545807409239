import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import logo from '../assets/images/logo.png'; // Importar la imagen desde la nueva ubicación

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>Mi Facturador APP - Sistema Facturación Electrónica</title>
        <meta name="description" content="Mi Facturador ofrece soluciones de facturación electrónica para empresas de todos los tamaños. Simplifica tu proceso de facturación con nuestra plataforma fácil de usar." />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Mi Facturador - Soluciones de Facturación Electrónica" />
        <meta property="og:description" content="Mi Facturador ofrece soluciones de facturación electrónica para empresas de todos los tamaños. Simplifica tu proceso de facturación con nuestra plataforma fácil de usar." />
        <meta property="og:image" content="https://mifacturador.net.pe/logo.png" />
        <meta property="og:url" content="https://mifacturador.net.pe" />
        <meta property="og:type" content="website" />

        {/* Meta Pixel Code */}
        <script async src="https://connect.facebook.net/en_US/fbevents.js"></script>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '905370004948601');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=905370004948601&ev=PageView&noscript=1"
            />
          `}
        </noscript>
        {/* End Meta Pixel Code */}

        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-0HTZD77S0Z"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-0HTZD77S0Z');
          `}
        </script>

        {/* JSON-LD */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "MiFacturador",
              "description": "Soluciones de facturación electrónica",
              "url": "https://mifacturador.net.pe",
              "telephone": "+51123456789",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Av. Example 123",
                "addressLocality": "Lima",
                "addressRegion": "Lima",
                "postalCode": "15001",
                "addressCountry": "PE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": -12.0464,
                "longitude": -77.0428
              },
              "openingHoursSpecification": [
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday"
                  ],
                  "opens": "09:00",
                  "closes": "18:00"
                }
              ]
            }
          `}
        </script>
      </Helmet>
      <header className="bg-gray-800 text-white flex justify-between items-center p-4 fixed w-full top-0 z-50">
        <div id="branding" className="flex items-center">
          <Link to="/">
            <img src={logo} alt="Mi Facturador" className="h-12 w-auto" />
          </Link>
        </div>
        <nav className="flex items-center flex-grow">
          <ul className={`nav-links flex flex-col md:flex-row md:mr-10 md:flex-grow md:justify-end ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <li className="md:ml-5"><HashLink smooth to="/#inicio" className="text-white uppercase text-sm">Inicio</HashLink></li>
            <li className="md:ml-5"><HashLink smooth to="/#caracteristicas" className="text-white uppercase text-sm">Características</HashLink></li>
            <li className="md:ml-5"><HashLink smooth to="/#beneficios" className="text-white uppercase text-sm">Beneficios</HashLink></li>
            <li className="md:ml-5"><HashLink smooth to="/#usecase" className="text-white uppercase text-sm">Testimonios</HashLink></li>
            <li className="md:ml-5"><HashLink smooth to="/#capturas" className="text-white uppercase text-sm">Screenshots</HashLink></li>
            <li className="md:ml-5"><HashLink smooth to="/#planes-tarifas" className="text-white uppercase text-sm">Precios</HashLink></li> {/* Añadido enlace a Precios */}
            <li className="md:ml-5"><HashLink smooth to="/#faq" className="text-white uppercase text-sm">FAQ</HashLink></li>
            <li className="md:ml-5"><HashLink smooth to="/#contacto" className="text-white uppercase text-sm">Contacto</HashLink></li>
            <li className="md:ml-5"><Link to="/blog" className="text-white uppercase text-sm">Blog</Link></li> {/* Actualizado para apuntar al componente Blog */}
            <li className="md:ml-5"><Link to="/consulta-documentos" className="text-white uppercase text-sm">( Comprobantes )</Link></li> {/* Actualizado para apuntar al componente Blog */}
          </ul>
          <div className="hamburger flex flex-col cursor-pointer ml-auto md:hidden" onClick={toggleMenu}>
            <div className="bar w-6 h-0.5 bg-white my-1"></div>
            <div className="bar w-6 h-0.5 bg-white my-1"></div>
            <div className="bar w-6 h-0.5 bg-white my-1"></div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;