import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center mb-6">
        <p className='font-bold'>© 2024 Mi Facturador. Todos los derechos reservados.</p>
      </div>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="text-center md:text-left">
          <p className="mb-2 font-bold">Enlaces Rápidos:</p>
          <ul className="list-none space-y-2">
            <li><button onClick={() => window.location.href='/sobre-nosotros'} className="hover:underline text-white">Sobre Nosotros</button></li>
            <li><button onClick={() => window.location.href='/politicas-privacidad'} className="hover:underline text-white">Política de Privacidad</button></li>
            <li><button onClick={() => window.location.href='/terminos-condiciones'} className="hover:underline text-white">Términos y Condiciones</button></li>
            <li><button onClick={() => window.location.href='/#contacto'} className="hover:underline text-white">Contacto</button></li>
            <li><button onClick={() => window.location.href='/#faq'} className="hover:underline text-white">FAQ</button></li>
          </ul>
        </div>
        <div className="text-center md:text-left">
          <p className="mb-2 font-bold">Nuestros Productos:</p>
          <ul className="list-none space-y-2">
            <li><a href="https://mifacturador.net.pe" className="hover:underline text-white">Mi Facturador</a></li>
            <li><a href="https://miboleta.net.pe" className="hover:underline text-white">Mi Boleta</a></li>
            <li>Mi Restaurant</li>
            <li>Mi Farmacia</li>
            <li>Mi Cochera</li>
            <li>Mi Deportivo</li>
            <li><a href="https://perubi.com/wanderlust" className="hover:underline text-white">Wanderlust</a></li>
          </ul>
        </div>
        <div className="text-center md:text-left">
          <p className="mb-2 font-bold">Contacto:</p>
          <p className="mb-2">Email: oscar.rodriguez@perubi.com</p>
          <p className="mb-2">Teléfono: +51 940 769 577</p>
          <p>Dirección: La Estancia de Lurin Mz RE Lote 05 - Lima - Perú</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;