import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

const Hero = lazy(() => import('./components/Hero'));
const Features = lazy(() => import('./components/Features'));
const Benefits = lazy(() => import('./components/Benefits'));
const UseCase = lazy(() => import('./components/UseCase'));
const CTA = lazy(() => import('./components/CTA'));
const Screenshots = lazy(() => import('./components/Screenshots'));
const FAQ = lazy(() => import('./components/FAQ'));
const LastPost = lazy(() => import('./components/LastPost'));
const Contact = lazy(() => import('./components/Contact'));
const AgendarCita = lazy(() => import('./components/AgendarCita'));
const BlogPost = lazy(() => import('./components/BlogPost'));
const Blog = lazy(() => import('./components/Blog'));
const BlogGenerative = lazy(() => import('./components/BlogGenerative'));
const PoliticasPrivacidad = lazy(() => import('./components/PoliticasPrivacidad'));
const TerminosCondiciones = lazy(() => import('./components/TerminosCondiciones'));
const SobreNosotros = lazy(() => import('./components/SobreNosotros'));
const PlanesTarifas = lazy(() => import('./components/PlanesTarifas'));
const ConsultaDocumentos = lazy(() => import('./components/ConsultaDocumentos'));

const App = () => {
  useEffect(() => {
    const worker = new Worker(new URL('./worker.js', import.meta.url));
    worker.postMessage(10);
    worker.onmessage = function (e) {
      console.log('Resultado del worker:', e.data);
    };

    // Cleanup the worker when the component unmounts
    return () => {
      worker.terminate();
    };
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={
            <>
             <section id="hero" className="pt-20">
                <Hero />
              </section>
              <section id="caracteristicas" className="pt-20">
                <Features />
              </section>
              <section id="beneficios" className="pt-20">
                <Benefits />
              </section>
              <section id="usecase" className="pt-20">
                <UseCase />
              </section>
              <section id="cta" className="pt-20">
                <CTA />
              </section>
              <section id="capturas" className="pt-20">
                <Screenshots />
              </section>
              <section id="planes-tarifas" className="pt-20">
                <PlanesTarifas />
              </section>
              <section id="faq" className="pt-20">
                <FAQ />
              </section>
              <section id="lastpost" className="pt-20">
                <LastPost />
              </section>
              <section id="contacto" className="pt-20">
                <Contact />
              </section>
            </>
          } />
          <Route path="/agendar-cita" element={<AgendarCita />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/generar-articulo" element={<BlogGenerative />} />
          <Route path="/politicas-privacidad" element={<PoliticasPrivacidad />} />
          <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
          <Route path="/sobre-nosotros" element={<SobreNosotros />} />
          <Route path="/consulta-documentos" element={<ConsultaDocumentos />} />
        </Routes>
      </Suspense>
      <Suspense fallback={<div>Loading Header...</div>}>
        <Header />
      </Suspense>
      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </Router>
  );
};

export default App;